<template>
  <div> 
    <div class="panel-body row">
      
      <div class="col-md-12 " style="margin-bottom:5px;">
        <div>
          <h4>Visitors</h4>
        </div>
        
      </div>
      <div class="col-md-6">
        <div style="padding: 10px; display:block; ">
            <dashbord-cards
              v-if="showChartVisitors"
              :charttype="cards[0].charttype"
              :chartdata="cards[0].chartdata"
              :type="cards[0].type"
              :cardBg="cards[0].cardBg"
              :name="cards[0].name"
              :value="cards[0].value"
              :icon="cards[0].icon"
              :styleChart="'auto'"
              @setDuration="getTotalVisitingChartData"
            />
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12" id="preview-area"  v-if="!showChartVisitors">
          <div class="row text-center">
              <div class="sk-chase">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
              </div>
          </div>
        </div>  
      </div>
      <div class="col-md-6">
        <div style="padding: 10px; display:block; ">
          <dashbord-cards
            v-if="showAvgChartVisitors"
            :charttype="cards[1].charttype"
            :chartdata="cards[1].chartdata"
            :type="cards[1].type"
            :cardBg="cards[1].cardBg"
            :name="cards[1].name"
            :value="cards[1].value"
            :icon="cards[1].icon"
            :styleChart="'auto'"
            @setDuration="getAvgVisitingChartData"
          />
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12" id="preview-area"  v-if="!showAvgChartVisitors">
          <div class="row text-center">
              <div class="sk-chase">
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
                  <div class="sk-chase-dot"></div>
              </div>
          </div>
        </div>  
      </div>
      </div>
    </div>
</template>
<script>
import DashbordCards from '@/components/common/Dashbord/DashbordCards';
import mixinDashboard from "@/mixins/merchant/APIDashboard";
export default {
  mixins: [mixinDashboard],
   components: { 
     DashbordCards
   },
  data: function () {
    return {
      breadcrumb: [
          {
              text: "Dashboard",
              href: "/merchant-dashbord",
              active: false
          },
          {
              text: "Visitors",
              active: true
          }
      ],
      showChartVisitors: true,
      showAvgChartVisitors: true,
      selected_option: 1,
      cards:[
        {
            cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
            name: "Visitors",
            value: "1,000",
            icon: "fa fa-truck",
            type: "single-view",
            charttype: 'DynamicChartBar',
            chartdata: {
              labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
              datasets: [
                {
                  label: 'Card',
                  backgroundColor: '#d49de1',
                  borderColor: "#ffcd05",
                  data: [],
                  fill: false
                }, 
              ]
            }
        },
        {
            cardBg: "background-color: #ffffff !important; color: #6a6a6a !important; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important; border: white;",
            name: "Average Visitors",
            value: "1,000",
            icon: "fa fa-truck",
            type: "single-view",
            charttype: 'DynamicChartBar',
            chartdata: {
              labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
              datasets: [
                {
                  label: 'Card',
                  backgroundColor: '#d49de1',
                  borderColor: "#ffcd05",
                  data: [],
                  fill: false
                }, 
              ]
            }
        }
      ]
    }
  },
  watch: {},
  created(){
    this.handleBreadcrumb();
    this.getTotalVisitingChartData(1);
    this.getAvgVisitingChartData(1);
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getTotalVisitingChartData: async function(id) {
        try {
          this.showChartVisitors = false;
          let response = await this.getTotalVisitingApi(id);
          if (response) {
            this.cards[0].value = response.count.toFixed(0) + "";
            response.chart_data.reverse();
            this.cards[0].chartdata.labels = [];
            this.cards[0].chartdata.datasets[0].data=[];
            response.chart_data.forEach(element => {
              this.cards[0].chartdata.labels.push(
                id==1?"Day "+element._id:id==2? "Week "+element._id: id== 3 ? "Month "+element._id : ""
              );
              this.cards[0].chartdata.datasets[0].data.push( element.total_count);
            });
          }
          this.showChartVisitors = true;
        } catch (error) {
          throw(error);
          this.showChartVisitors = true;
        }
      },
    getAvgVisitingChartData: async function(id) {
      try {
        this.showAvgChartVisitors = false;
        let response = await this.getVisitsChartDataApi(id);
        if(response){
          this.cards[1].value = response.count.toFixed(0) + "";
          response.chart_data.reverse();
          this.cards[1].chartdata.labels = [];
          this.cards[1].chartdata.datasets[0].data=[];
            response.chart_data.forEach(element => {
              this.cards[1].chartdata.labels.push(
                id==1?"Day "+element._id:id==2? "Week "+element._id: id== 3 ? "Month "+element._id : ""
              );
              this.cards[1].chartdata.datasets[0].data.push( element.avgValue);
            });
          }
        this.showAvgChartVisitors = true;
      } catch (error) {
        this.showAvgChartVisitors = true;
        throw(error); 
      }
    }
  }
}
</script>
<style >
#chart-bar-0 {
    height: 550px!important;
}
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 265px;
}
.head-table {
  background: white !important;
  text-align: left;
}
.table-border-out {
    border: 1px solid #ddd;
}
</style>